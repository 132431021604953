import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import Fliesstext from "../Text/Fliesstext";
import GallerySelection from "./GallerySelection";
import { breakpoints, imgix } from "../../styles/variables";
import { getImgix } from "../helpers/getImgix";

const GalleryDiv = styled.div`
  width: 100%;
  .carousel-cell {
    width: 100%;

    > div:first-of-type {
      width: 100%;
      height: 100%;
      margin-bottom: 16px;
      > div {
        width: 100%;
        height: 50vw;
        background-position: center;
        background-size: cover;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:hover {
          .prevNextArrows {
            opacity: 1;
          }
        }

        .prevNextArrows {
          opacity: 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 20px;
          transition: 300ms;
          > div img {
            width: 30px;
          }
        }
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    .carousel-cell {
      > div:first-of-type {
        > div {
          height: 488px;
        }
      }
    }
  }
`;

const BackgroundElem = styled.div`
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`;

const Gallery = ({ data }) => {
  const [selector, setSelector] = useState(0);
  const [noChangeSelector, setNoChangeSelector] = useState(0);
  const [init, setInit] = useState(true);

  const flickityOptions = {
    intitialIndex: 0,
    pageDots: false,
    prevNextButtons: false,
  };

  const flkty = useRef(null);

  const next = () => flkty.current.next();
  const prev = () => flkty.current.previous();

  useEffect(() => {
    flkty.current.select(selector, false, false);
  }, [selector]);

  useEffect(() => {
    flkty.current.on("settle", () => {
      setNoChangeSelector(flkty.current.selectedIndex);
    });

    setTimeout(() => {
      setInit(false);
    }, 300);
  }, []);

  return (
    <>
      <GalleryDiv>
        <Flickity
          className={"carousel"} // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          disableImagesLoaded={false} // default false
          reloadOnUpdate={init}
          flickityRef={(carouselRef) => {
            flkty.current = carouselRef;
          }}
        >
          {data.galerieInhalt.map((item, index) => (
            <div className="carousel-cell" key={index}>
              <div>
                {item.bild && item.bild.length > 0 ? (
                  <BackgroundElem
                    src={getImgix(item.bild[0].url, imgix.galleryImage).newSrc}
                  >
                    <div className="prevNextArrows">
                      <div onClick={() => prev()}>
                        <img src="/assets/gestaltungselemente/arrow-prev.svg" />
                      </div>
                      <div onClick={() => next()}>
                        <img src="/assets/gestaltungselemente/arrow-next.svg" />
                      </div>
                    </div>
                  </BackgroundElem>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Fliesstext text={item.bildBeschreibung}></Fliesstext>
              </div>
            </div>
          ))}
        </Flickity>
      </GalleryDiv>
      <GallerySelection
        data={data.galerieInhalt}
        setSelector={setSelector}
        noChangeSelector={noChangeSelector}
      />
    </>
  );
};

export default Gallery;
