import { graphql } from "gatsby";
import React from "react";
import DefaultLayout from "../layouts/default";
import Akkordeon from "../components/Akkordeon/Akkordeon";
import Gallery from "../components/Akkordeon/Gallery";

interface UeberUnsProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        metabeschreibung: string;
      };
    };
  };
}

export const MitarbeiterQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId
        ... on CMS_main_galerie_Entry {
          metaBeschreibung
          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
          }
        }

        children {
          title
          ... on CMS_main_galerieSektion_Entry {
            galerieInhalt {
              ... on CMS_galerieInhalt_bild_BlockType {
                bild {
                  url
                }
                bildBeschreibung
              }
            }
          }
        }
      }
    }
  }
`;

const UeberUns: React.FC<UeberUnsProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.children.map((entry, index) => (
        <Akkordeon
          title={`<h2>${entry.title}</h2>`}
          open={index === 0 ? true : false}
          key={index}
        >
          <Gallery data={entry} />
        </Akkordeon>
      ))}
    </DefaultLayout>
  );
};

export default UeberUns;
