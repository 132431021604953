import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const TitleDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.text};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  padding-bottom: 16px;

  h2 {
    font: 20px / 30px "FS Medium";
    b,
    strong {
      font-family: "FS Medium";
    }
  }

  .toggle {
    width: 28px;
    height: 28px;
    background: url("/assets/gestaltungselemente/icon-akkordeon-toggle.svg");
    background-size: 28px 14px;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 300ms;
  }

  &.open {
    .toggle {
      transform: rotate(180deg);
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    h2 {
      font: 28px / 36px "FS Regular";
    }

    .toggle {
      width: 40px;
      height: 40px;
      background-size: 40px 20px;
    }
  }
`;

interface TitleProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const Title: React.FC<TitleProps> = ({ isOpen, setIsOpen, title }) => (
  <TitleDiv onClick={() => setIsOpen(!isOpen)} className={isOpen ? "open" : ""}>
    <div dangerouslySetInnerHTML={{ __html: title }}></div>
    <div className="toggle" title="Akkordeon Ein-/Aufklapp-Icon" />
  </TitleDiv>
);

export default Title;
