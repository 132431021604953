import React, { useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";
import Content from "./Content";
import Title from "./Title";

const AkkordeonItem = styled.article`
  margin-bottom: 30px;

  &.final {
    margin-bottom: 70px;
  }

  &.open {
    margin-top: 40px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 40px;

    &.final {
      margin-bottom: 110px;
    }

    &.open {
      margin-top: 70px;
    }
  }
`;

interface AkkordeonProps {
  title: string;
  final: boolean;
}

const Akkordeon: React.FC<AkkordeonProps> = ({
  children,
  title,
  final,
  open,
}) => {
  const [isOpen, setIsOpen] = useState(open ? true : false);
  return (
    <Wrapper>
      <AkkordeonItem
        className={
          final && open ? "open final" : final ? "final" : open ? "open" : ""
        }
      >
        <Title isOpen={isOpen} setIsOpen={setIsOpen} title={title} />
        <Content isOpen={isOpen}>{children}</Content>
      </AkkordeonItem>
    </Wrapper>
  );
};

export default Akkordeon;
