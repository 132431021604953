import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const ContentElem = styled.div`
  overflow: hidden;
  > div {
    margin-top: -700%;
    transition: margin 900ms;
    padding: 44px 0;
  }

  &.open {
    > div {
      margin-top: 0;
    }
  }
  @media screen AND (min-width: ${breakpoints.desktop}px) {
  }
`;

interface ContentProps {
  isOpen: boolean;
}

const Content: React.FC<ContentProps> = ({ isOpen, children }) => (
  <ContentElem className={isOpen ? "open" : ""}>
    <div>{children}</div>
  </ContentElem>
);

export default Content;
