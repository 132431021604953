import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../../styles/variables";

import ImgixImg from "../helpers/ImgixImg";

const GallerySelectionElem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  > div,
  > div > img {
    width: 100%;
    cursor: pointer;
  }

  > div {
    height: 24vw;

    > img {
      height: 24vw;
      object-fit: cover;
    }

    &.active {
      > img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        opacity: 0.52;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    > div {
      height: 18vw;

      > img {
        height: 18vw;
      }
    }
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 40px;

    > div {
      height: 127px;

      > img {
        height: 127px;
      }
    }
  }
`;

const GallerySelection = ({ data, setSelector, noChangeSelector }) => (
  <GallerySelectionElem>
    {data.map((item, index) =>
      item.bild.length > 0 ? (
        <div
          onClick={() => setSelector(index)}
          className={noChangeSelector === index ? "active" : ""}
          key={index}
        >
          <ImgixImg src={item.bild[0].url} imgixParams={imgix.galleryGrid} />
        </div>
      ) : (
        ""
      )
    )}
  </GallerySelectionElem>
);

export default GallerySelection;
